// Require optional shared components
require(['componentLoader'], function(helper) {
  var config = THEHUT.config;



  // Add new locally mapped modules and other dependencies
  helper.promote([
    'productPageSlider',
    'carousel',
    'carouselSet',
    'sliderSet'
  ]);

  helper.requireDependencies();
});

// This module's dependencies only
var deps = [
  'jquery',
  'colourswatches',

  // Undeclared
  'colourswatchesTpl',
  'common'
];

require(deps, function($, colourswatches) {

  // Add localised JS here
  colourswatches.colourSwatchesForSelectBoxWithLabel('opts-4');

  // Bundle Products Accordion
  var accordionHeadings = ('.bundle-section .widget-header');
  var accordionSiblings = $(accordionHeadings).siblings('.bundle-product-wrap');

  $(accordionSiblings).first().slideToggle().parent().addClass('selected');
  $(accordionHeadings).click(function() {
    var $this = $(this).siblings('.bundle-product-wrap');
    if ($this.is(':animated')) {
      return false;
    }
    if ($this.parent().hasClass('selected')) {
      $this
        .slideToggle()
        .parent()
        .removeClass('selected');

    } else {
      $this.parent()
        .removeClass('selected')
        .find(accordionSiblings)
        .slideUp();
      $this
        .slideToggle()
        .parent()
        .addClass('selected');
    }
    return false;
  });


  /*** BUNDLE FUNCTIONALITY ***/ // Query's? IMPLEMENTED BY TOM S FEDs

  if (document.querySelectorAll('[data-component="ebundle"]').length) {
    require(['bundleController'], function(bundleController) {
      var $bundleWrapper = $('[data-component="ebundle"]');

      bundleController.init({
        $bundleWrapper: $bundleWrapper,
        $products: $bundleWrapper.find('.item'),
        $bundleBtn: $bundleWrapper.find('.bundle-button'),
        $bundleSummary: $bundleWrapper.find('.bundle-summary'),
        footerDistance: $('.footer').offset().top
      });
    });
  }

/*** END OF BUNDLE FUNCTIONALITY ***/
});

/* Youtube videos /videos/youtube-main.js - load the API and render via youtubePlayer */
require(['youtube'], function(youtube) {
  youtube('.js-youtube-video').done(function(videosArray) {
    // ytVideosArray: array of Youtube videos and player Api controls for further customisation

    var activeYTThumbClass = 'widget-youtube__gallery__menu__items__item--active';
    $('.youtube-video__gallery__menu__item').on('click', function() {
      $('.youtube-video__gallery__menu__item').removeClass(activeYTThumbClass);
      $(this).addClass(activeYTThumbClass);
    });
  });
});
